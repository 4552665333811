<template>
    <div id="bg">
      <div class="search">
        <div class="search-box">
          <span class="search-box-title">账号：</span>
          <el-input v-model="search.username" class="width_200" placeholder="请输入账号" size="medium" clearable></el-input>
        </div>
        <div class="search-box">
          <span class="search-box-title">IP：</span>
          <el-input v-model="search.ip" class="width_200" placeholder="请输入IP" size="medium" clearable></el-input>
        </div>
        <div class="search-box">
          <span class="search-box-title">在线状态：</span>
          <el-select v-model="search.online" class="width_80">
            <el-option label="全部" value="-1"></el-option>
            <el-option label="离线" value="0"></el-option>
            <el-option label="在线" value="1"></el-option>
          </el-select>
        </div>
        <div class="search-box">
          <el-button type="primary" size="medium" @click="(pager.page = 1),getList()">查询</el-button>
        </div>
        <div class="search-box">
          <el-button type="primary" size="medium" @click="dialog = true;title = '添加用户';dialogArr = {}">添加用户</el-button>
        </div>
      </div>
      <el-table :data="tableData" :header-cell-style="{background:'#F7F8FA'}">
        <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="username" label="用户名" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="remark" label="备注" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="price" label="金额" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="black_ip" label="IP白名单" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="login_ip" label="登陆IP" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="online" label="是否在线" align="center" :show-overflow-tooltip="true">
          <template #default="scope">
            <div v-if="scope.row.online == 1 " :style="{color: '#33C933' }">在线</div>
            <div v-if="scope.row.online == 0 " :style="{color: '#bdbdbd'}">离线</div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="是否冻结" align="center" :show-overflow-tooltip="true">
          <template #default="scope">
            <el-switch v-model="scope.row.status" :active-value=0 :inactive-value=1 @change="useAccount(scope.row)" />
          </template>
        </el-table-column>
        <el-table-column prop="state" label="是否接单" align="center" :show-overflow-tooltip="true">
          <template #default="scope">
            <el-switch v-model="scope.row.state" :active-value=1 :inactive-value=0 @change="stateSwitch(scope.row)" />
          </template>
        </el-table-column>
        <el-table-column prop="pay_type" label="充值类型" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="操作" width="250px" align="center">
          <template #default="scope">
            <span class="operation" @click="edit(scope.row)">编辑</span>
            <span class="operation" @click="editScore(scope.row)">上分/追分</span>
             <span class="operation" @click="del(scope.row)">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <page :pager="pager" @query="getList()" />
  
      <!-- 添加编辑 -->
      <el-dialog custom-class="dialog" :title="title" v-model="dialog" width="492px" top="35vh" :close-on-click-modal="false" @close="closeDialog">
        <div class="form_box" v-if="title == '添加用户'">
          <div class="form_title">用户名：</div>
          <el-input v-model="dialogArr.username" placeholder="用户名" size="small"></el-input>
        </div>
        <div class="form_box" v-if="title == '添加用户'">
          <div class="form_title">密码：</div>
          <el-input v-model="dialogArr.password" placeholder="密码" size="small"></el-input>
        </div>
        <div class="form_box">
          <div class="form_title">IP白名单：</div>
          <el-input v-model="dialogArr.black_ip" placeholder="IP白名单" size="small"></el-input>
        </div>
        <div class="form_box">
          <div class="form_title">备注：</div>
          <el-input v-model="dialogArr.remark" placeholder="备注" size="small"></el-input>
        </div>
        <el-divider v-if="title != '添加用户'"></el-divider>
        <div class="form_box" v-if="title != '添加用户'">
          <div class="form_title">新密码：</div>
          <el-input v-model="dialogArr.new_password" placeholder="请输入新密码" size="small" :disabled="disabled"></el-input>
          <el-switch v-model="passwordOpen" :active-value=1 :inactive-value=0 @change="open" style="margin-left: 20px;" />
        </div>
        <div class="form_box" v-if="title == '添加用户'">
          <div class="form_title">谷歌验证码：</div>
          <el-input v-model="dialogArr.google_code" placeholder="谷歌验证码" size="small"></el-input>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="addUser" v-if="title == '添加用户'">确认添加</el-button>
            <el-button type="primary" @click="updateUser" v-else>确认修改</el-button>
          </span>
        </template>
      </el-dialog>

      <!-- 上分/追分 -->
      <el-dialog custom-class="dialog" title="上分/追分" v-model="dialogScore" width="492px" top="35vh" :close-on-click-modal="false" @close="closeDialog">
        <el-form status-icon ref="scoreForm" :model="scoreForm" label-width="100px">
            <el-form-item label="操作用户">
              <div>{{ row.username }}</div>
            </el-form-item>
            <el-form-item label="金额">
              <el-input v-model="scoreForm.price" placeholder="请输入金额" class="identifyinput"></el-input>
              <el-checkbox v-model="scoreForm.checked" style="margin-left: 20px;" @change="toZero">归零</el-checkbox>
            </el-form-item>
            <!-- 随机验证码 输入框 -->
            <el-form-item label="验证码">
                <el-input v-model="scoreForm.verifycode" placeholder="请输入验证码" class="identifyinput"></el-input>
                <!-- 随机验证码 -->
                <div @click="refreshCode" style="float: right;">
                    <s-identify :identifyCode="identifyCode"></s-identify>
                </div>
            </el-form-item>
          <el-form-item label="谷歌验证码">
            <el-input v-model="scoreForm.google_code" placeholder="请输入谷歌验证码" class="identifyinput"></el-input>
          </el-form-item>
            <el-form-item label="操作方式">
              <el-radio v-model="scoreForm.radio" label="1">上分</el-radio>
              <el-radio v-model="scoreForm.radio" label="2">追分</el-radio>
            </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="updateUserScore">确认修改</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import {
    getList,
    addUser,
    updateUser,
    updateUserScore,
    useAccount,
    stateSwitch,
    del,
  } from "@/api/ys/list";
  import page from "@/components/page";
  import SIdentify from "@/components/SIdentify";
  export default {
    name: "ysList",
    components: {
      page,
      SIdentify,
    },
    data() {
      return {
        pager: { total: 0, page: 1, rows: 10 },
        search: {
          //搜索
          username: "", // 用户名
          ip: "", // ip
          online:"-1"
        },
        tableData: [], //数据
        dialog: false, // 添加编辑
        dialogScore: false, // 上分/追分
        title: "", // 标题
        row: {}, // 选中的数据
        dialogArr: {
          // 编辑数据
          username: "", // 用户名
          password: "", // 密码
          new_password: "", // 新密码
          // nickname: "", // 昵称
          remark: "", // 备注
          status: 0, // 0=冻结，1=正常
          online: 0, // 0=离线，1=在线
          state: 0, // 0=未准备好，1=已准备好
          pay_type: "", // 充值类型(多个逗号隔开)
          black_ip: "", // IP白名单
          price: "", // 余额
          google_code: "", // 余额
        },
        switch: false, // 开关
        passwordOpen: 0, // 修改密码开关
        disabled: true, // 修改密码输入框是否禁用
        // checked: false, // 金额是否归零
        // radio: '1', // 上分/追分
        scoreForm: {
            price: '',
            checked: false, // 金额是否归零
            verifycode: '',
            google_code: '',
            radio: '1', // 上分/追分
        },
        identifyCodes: '1234567890',
        identifyCode: '',
      };
    },
    computed: {},
    created() {},
    mounted() {
      this.getList();
      // 验证码初始化
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
    },
    methods: {
      // 生成随机数
      randomNum(min, max) {
          return Math.floor(Math.random() * (max - min) + min)
      },
      // 切换验证码
      refreshCode() {
          this.identifyCode = ''
          this.makeCode(this.identifyCodes, 4)
      },
      // 生成四位随机验证码
      makeCode(o, l) {
          for (let i = 0; i < l; i++) {
              this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)]
          }
          // console.log(this.identifyCode)
      },

      //获取数据
      getList() {
        getList([
          { key: "page", val: this.pager.page - 1 },
          { key: "row", val: this.pager.rows },
          { key: "username", val: this.search.username},
          { key: "ip", val: this.search.ip},
          { key: "online", val: this.search.online},
        ])
          .then((res) => {
            if (res.code == 0) {
              if(this.pager.page == 1){
                this.pager.total = res.data.total; // 第一页才会改总记录数
              }
              this.tableData = res.data.list;
              this.switch = true;
            } else {
              this.$message({ message: res.msg, type: "error" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
    
      // 添加用户
      addUser() {
        addUser([
            { key: "username", val: this.dialogArr.username },
            { key: "password", val: this.dialogArr.password },
            { key: "google_code", val: this.dialogArr.google_code },
            { key: "remark", val: this.dialogArr.remark },
            { key: "black_ip", val: this.black_ip },
        ]).then((res) => {
            if (res.code == 0) {
                this.getList();
                this.$message({ message: res.msg, type: "success" });
                this.dialog = false;
            } else {
                this.$message({ message: res.msg, type: "error" });
            }
            })
            .catch((err) => {
                console.log(err);
            });
      },
      // 修改密码开关
      open() {
        console.log(this.passwordOpen);
        if(this.passwordOpen == 1) {
          this.disabled = false;
        }else {
          this.disabled = true;
        }
      },
      // 编辑前填充数据
      edit(row) {
        this.row = row;
        this.dialog = true;
        this.title = `编辑用户ID:${row.id}`;
        this.dialogArr = {
          // username: row.username,
          // password: row.password,
          // nickname: row.nickname,
          black_ip: row.black_ip,
          remark: row.remark,
        };
      },
      // 编辑
      updateUser() {
        updateUser([
            { key: "id", val: this.row.id },
            // { key: "username", val: this.dialogArr.username },
            // { key: "password", val: this.dialogArr.password },
            // { key: "nickname", val: this.dialogArr.nickname },
            { key: "black_ip", val: this.dialogArr.black_ip },
            { key: "remark", val: this.dialogArr.remark },
            { key: "password", val: this.dialogArr.new_password },
        ]).then((res) => {
            if (res.code == 0) {
              this.dialog = false;
              this.getList();
              this.$message({ message: res.msg, type: "success" });
            } else {
              this.$message({ message: res.msg, type: "error" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      // 上分/追分
      editScore(row){
        this.row = row;
        this.dialogScore = true;
      },
      toZero() {
        if(this.scoreForm.checked == true) {
          this.dialogArr.price = 0
        }
      },
      updateUserScore(){
        if(this.scoreForm.price == "") {
          return this.$message({ message: '请输入金额', type: "error" });
        }
        if(this.scoreForm.verifycode != this.identifyCode) {
          return this.$message({ message: '验证码不正确', type: "error" });
        }
        if(this.scoreForm.radio == 1) {
          this.scoreForm.price = +this.scoreForm.price
        }else if(this.scoreForm.radio == 2) {
          this.scoreForm.price = -this.scoreForm.price
        }
        updateUserScore([
            { key: "id", val: this.row.id },
            { key: "price", val: this.scoreForm.price },
            { key: "toZero", val: this.scoreForm.checked },
            { key: "google_code", val: this.scoreForm.google_code },
        ]).then((res) => {
            if (res.code == 0) {
              this.scoreForm.price = "";
              this.$message({ message: res.msg, type: "success" });
              this.dialogScore = false;
              this.getList();
            } else {
              this.scoreForm.price = "";
              this.$message({ message: res.msg, type: "error" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },

      // 禁用启用
      useAccount(row) {
        if (!this.switch) return;
        useAccount([
          { key: "id", val: row.id },
          { key: "status", val: row.status },
        ]).then((res) => {
            if (res.code == 0) {
              this.$message({ message: res.msg, type: "success" });
              this.getList();
            } else {
              this.$message({ message: res.msg, type: "error" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      // 开启、关闭接单
      stateSwitch(row) {
        if (!this.switch) return;
        stateSwitch([
          { key: "id", val: row.id },
          { key: "state", val: row.state },
        ])
          .then((res) => {
            if (res.code == 0) {
              this.$message({ message: res.msg, type: "success" });
              this.getList();
            } else {
              this.$message({ message: res.msg, type: "error" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      // 删除
      del(row) {
      this.$confirm("你确定要删除：" + row.username + "吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          del([{ key: "id", val: row.id }])
            .then((res) => {
              if (res.code == 0) {
                this.$message({ message: res.msg, type: "success" });
                this.dialogAdd = false;
                this.getList();
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .randomcodeuse{
      width: 60%;
      margin: auto;
      display: flex;
      align-items: center;
  }
  .identifybox {
      display: flex;
      justify-content: space-between;
      margin-top: 7px;
  }
  .identifyinput {
    width: 200px;
  }

  .form_box {
    overflow: hidden;
    margin-bottom: 18px;
  }
  .form_title {
    width: 20%;
    float: left;
    height: 32px;
    line-height: 32px;
  }
  .form_box >>> .el-input,
  .form_box >>> .el-textarea {
    width: 60%;
  }
  .form_box >>> .el-select .el-input {
    width: 50%;
  }
  .form_box >>> .noteRed {
    color: red;
    margin-top: 5%;
    font-size: 13px;
  }
  </style>